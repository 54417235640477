import React from "react";

import { GET_GLOBAL_DISCOUNTS } from "GraphQLQueries";
import { useMothershipMutation, useMothershipQuery } from "Hooks";
import { Box, Button, Flex, Header, Indicator, LoadingMessage, Stack, Text } from "Atoms";
import { useModal } from "Providers/modalProvider";
import { useLanguage } from "LanguageProvider";
import { modalNames } from "Constants";
import { BOCard, BOCardBody, BOCardFooter } from "../../../shared";
import { CountryLocale, CountryLocaleId, CurrencySymbol, Discount } from "Types";
import { formatFinancialNumbers, isGodMode, isSuperAdmin } from "Utils";
import { DiscountDate } from "../../discount-admin/components/DiscountDate";
import { DELETE_GLOBAL_DISCOUNT } from "GraphQLMutations";
import { confirmNotification } from "TempUtils";
import { useQopla } from "Providers/qoplaProvider";

export const GlobalDiscount = () => {
    const { translate } = useLanguage();
    const { openModal, closeModal } = useModal();
    const { authenticatedUser } = useQopla();

    const _isGodMode = isGodMode(authenticatedUser.roles);
    const { data, loading, refetch } = useMothershipQuery(GET_GLOBAL_DISCOUNTS, { skip: !_isGodMode });

    const [deleteGlobalDiscountMutation] = useMothershipMutation(DELETE_GLOBAL_DISCOUNT);

    const openGlobalDiscountModal = (discount?: Discount) => {
        openModal(modalNames.GLOBAL_DISCOUNT, { closeModal, ...(!!discount && { discount }), refetch });
    };

    const countryLocale: CountryLocale = {
        localeCode: CountryLocaleId.sv_SE,
        currencyCode: CurrencySymbol.sv_SE
    };

    const locale = new Intl.Locale(countryLocale.localeCode);

    const discounts = data?.getGlobalDiscounts || [];

    if (loading) {
        return <LoadingMessage />;
    }

    const onDeleteGlobalDiscount = async (discountId: string, discountName: string) => {
        const { value } = await confirmNotification(
            `${translate("delete")} - '${discountName}'?`,
            translate("areYouSure"),
            translate("yes"),
            "#d33"
        );

        if (!!value) {
            const { data } = await deleteGlobalDiscountMutation({ variables: { discountId } });
            if (!!data?.deleteGlobalDiscount) {
                refetch();
            }
        }
    };

    return (
        <Flex direction={"column"}>
            <Flex width={"100%"} flexWrap={"wrap"}>
                {discounts.map((discount: any) => {
                    const maxRedeemed = discount?.maxRedeemed ?? 0;
                    const numberUsed = discount?.noRedeemed ?? 0;
                    const hasMaxRedeemd = maxRedeemed > 0;
                    const noHasBeenUsed = numberUsed > 0;
                    const hasReachedTheMax = hasMaxRedeemd && (maxRedeemed === numberUsed || numberUsed > maxRedeemed);
                    const indicatorColor = discount.disabled
                        ? "inactive"
                        : hasReachedTheMax
                        ? "intermediary"
                        : "positive";
                    return (
                        <BOCard ml={2} mb={4} width={"max-content"} minWidth={"300px"} key={discount.id}>
                            <BOCardBody>
                                <Stack>
                                    <Header size="sm">
                                        <Flex align="baseline" justify="space-between">
                                            {discount.name}
                                            <Flex ml={4} justifyContent="center" alignItems="center">
                                                <Indicator status={indicatorColor} />
                                            </Flex>
                                        </Flex>
                                    </Header>
                                    {hasMaxRedeemd && (
                                        <Text>
                                            <Text as="b">{`${translate("maxUsage")}: `} </Text>
                                            {discount.maxRedeemed}
                                        </Text>
                                    )}
                                    {noHasBeenUsed && (
                                        <Text>
                                            <Text as="b">{`${translate("discountHasBeenUsed")}: `}</Text>
                                            {discount.noRedeemed}
                                        </Text>
                                    )}
                                    <Text>
                                        <Text as="b">{translate("discount")} </Text>
                                        <Box as="span">
                                            {formatFinancialNumbers(
                                                discount.amount,
                                                locale,
                                                countryLocale.currencyCode,
                                                true
                                            )}
                                        </Box>
                                    </Text>
                                    <Text>
                                        <Text as="b">{translate("minimumSpend")} </Text>
                                        <Box as="span">
                                            {formatFinancialNumbers(
                                                discount.minimumSpend,
                                                locale,
                                                countryLocale.currencyCode,
                                                true
                                            )}
                                        </Box>
                                    </Text>
                                    <DiscountDate
                                        startDate={discount.startDate?.toString()}
                                        endDate={discount.endDate?.toString()}
                                    />
                                </Stack>
                            </BOCardBody>
                            <BOCardFooter flexDirection="column" height="auto">
                                <Flex>
                                    <Button
                                        marginRight={4}
                                        size={"sm"}
                                        onClick={() => openGlobalDiscountModal(discount)}
                                    >
                                        {translate("modify")}
                                    </Button>
                                    <Button
                                        themeColor="red"
                                        size={"sm"}
                                        onClick={() => onDeleteGlobalDiscount(discount.id, discount.name)}
                                    >
                                        {translate("delete")}
                                    </Button>
                                </Flex>
                            </BOCardFooter>
                        </BOCard>
                    );
                })}
            </Flex>

            <Button themeColor="blue" onClick={() => openGlobalDiscountModal()}>
                {translate("createANewDiscount")}
            </Button>
        </Flex>
    );
};
