import React, { useEffect } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment, { Moment } from "moment";

import { modalNames } from "Constants";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import {
    Button,
    RHFormInput,
    RHTextAreaInput,
    NewGrid as Grid,
    RHDatePickerInput,
    RHCheckBoxInput,
    RHSelectInput,
    SelectOption
} from "Atoms";
import { useLanguage } from "LanguageProvider";
import { CountryLocaleId, QoplaPromotionType } from "Types";
import { getMinimumEndDateFromStartDate, parseDateForJavaLocalDateTime, showToastSuccess } from "Utils";
import { useMothershipMutation, useMothershipQuery } from "Hooks";
import { GET_COMPANIES_FOR_SELECT } from "GraphQLQueries";
import { SAVE_NEW_QOPLA_PROMOTION } from "GraphQLMutations";

type ModalProps = {
    modalContent: {
        qoplaPromotion: QoplaPromotionType;
        refetchQoplaPromotions: () => Promise<void>;
    };
    closeModal: (modal: string) => void;
};

type QoplaPromotionFormType = QoplaPromotionType & { isActive: boolean };

export const QoplaPromotionNotificationModal: React.FC<ModalProps> = ({ modalContent, closeModal }) => {
    const { translate } = useLanguage();

    const { qoplaPromotion, refetchQoplaPromotions } = modalContent;

    const isEditMode = !!qoplaPromotion?.id;

    const { data, loading } = useMothershipQuery<{ getCompaniesForSelect: SelectOption[] }>(GET_COMPANIES_FOR_SELECT);

    const [saveQoplaPromotion] = useMothershipMutation(SAVE_NEW_QOPLA_PROMOTION);

    const qoplaPromotionSchema = Yup.object().shape({
        name: Yup.string().required(translate("mustFillIn")),
        promotionMessage: Yup.string().required(translate("mustFillIn")),
        notificationStartDate: Yup.date()
            .required(translate("mustChooseAStartDate"))
            .test("validateStartDate", translate("mustChooseAStartDate"), (value: moment.Moment) => {
                return moment(value).isValid();
            }),
        notificationEndDate: Yup.date()
            .required(translate("mustChooseAnEndDate"))
            .test("validateEndDate", translate("mustChooseAnEndDate"), (value: moment.Moment) => {
                return moment(value).isValid();
            }),
        collectContactInformation: Yup.boolean(),
        contactButtonText: Yup.string().when("collectContactInformation", {
            is: true,
            then: (schema: Yup.StringSchema) => schema.required(translate("mustFillIn")),
            otherwise: (schema: Yup.StringSchema) => schema.notRequired()
        }),
        targetAllCompanies: Yup.boolean(),
        companyIds: Yup.array()
            .when("targetAllCompanies", {
                is: false,
                then: Yup.array().min(1, translate("mustSelectAtLeastOne")),
                otherwise: Yup.array().notRequired()
            })
            .nullable()
    });

    const defaultValues = {
        ...qoplaPromotion,
        isActive: true,
        targetAllCompanies: true,
        ...(isEditMode && {
            isActive: !qoplaPromotion?.disabled,
            targetAllCompanies: !!qoplaPromotion?.targetAllCompanies
        })
    };

    const {
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = useForm<QoplaPromotionFormType>({
        mode: "onBlur",
        defaultValues: defaultValues,
        resolver: yupResolver(qoplaPromotionSchema)
    });

    const _notificationStartDate = useWatch({ control, name: "notificationStartDate" });
    const _notificationEndDate = useWatch({ control, name: "notificationEndDate" });
    const _targetAllCompanies = useWatch({ control, name: "targetAllCompanies" });
    const _collectContactInformation = useWatch({ control, name: "collectContactInformation" });

    const minimumEndDate = getMinimumEndDateFromStartDate(_notificationStartDate?.toString());

    const onHandleCloseModal = () => {
        closeModal(modalNames.QOPLA_PROMOTION_NOTIFICATION_MODAL);
    };

    const onSubmit: SubmitHandler<QoplaPromotionFormType> = async (values: QoplaPromotionFormType) => {
        const { isActive, ...qoplaPromotion } = {
            ...values,
            disabled: !values.isActive,
            notificationStartDate: !!values.notificationStartDate
                ? parseDateForJavaLocalDateTime(moment(values.notificationStartDate).startOf("day"))
                : "",
            notificationEndDate: !!values?.notificationEndDate
                ? parseDateForJavaLocalDateTime(moment(values.notificationEndDate).endOf("day"))
                : null,
            contactButtonText: !!values.collectContactInformation ? values.contactButtonText : null,
            companyIds: !values.targetAllCompanies ? values.companyIds : null
        };

        try {
            const { data } = await saveQoplaPromotion({
                variables: { qoplaPromotion: qoplaPromotion as QoplaPromotionType }
            });
            if (data) {
                closeModal(modalNames.QOPLA_PROMOTION_NOTIFICATION_MODAL);
                refetchQoplaPromotions();
                const updatedOrCreated = isEditMode ? translate("updated") : translate("created");
                const promotionCreated = `${translate("qoplaPromotion")} ${values.name} - ${updatedOrCreated}`;
                showToastSuccess(promotionCreated);
            }
        } catch (error) {
            console.error("Posting qopla promotion failed!!", error);
        }
    };

    const changeEndDateIfBeforeStartDate = (startDate: Moment) => {
        if (_notificationEndDate && moment(_notificationEndDate).isBefore(startDate)) {
            setValue("notificationEndDate", startDate.toDate());
        }
    };

    useEffect(() => {
        if (_targetAllCompanies) {
            setValue("companyIds", [], { shouldValidate: true, shouldDirty: true });
        }
    }, [_targetAllCompanies]);

    useEffect(() => {
        if (!_collectContactInformation) {
            setValue("contactButtonText", "", { shouldValidate: true, shouldDirty: true });
        }
    }, [_collectContactInformation]);

    const datePickerLocaleCode = CountryLocaleId.sv_SE;
    const companies = data?.getCompaniesForSelect || [];

    return (
        <Modal open placement="center" size={"xxl"} onClose={onHandleCloseModal}>
            <ModalCloseBtn onClick={onHandleCloseModal} />
            <ModalHeader>{"Qopla Promotion"}</ModalHeader>

            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody overflow={"auto"}>
                    <RHFormInput
                        name="name"
                        fullWidth
                        formLabel={translate("name")}
                        control={control}
                        placeholder={translate("name")}
                        isMandatory
                    />
                    <RHTextAreaInput
                        name="promotionMessage"
                        fullWidth
                        maxLength={225}
                        formLabel={translate("message")}
                        control={control}
                        isMandatory
                    />
                    <RHCheckBoxInput
                        formLabel={translate("collectContactInformation")}
                        name="collectContactInformation"
                        id="collectContactInformation"
                        control={control}
                    />

                    {_collectContactInformation && (
                        <RHFormInput
                            formLabel={translate("confirmationButtonText")}
                            name="contactButtonText"
                            id="contactButtonText"
                            max={20}
                            control={control}
                            isMandatory={_collectContactInformation}
                        />
                    )}

                    <Grid templateColumns="1fr 1fr" gap="1em">
                        <RHDatePickerInput
                            isFullWidth
                            formLabel={translate("notificationStartDate")}
                            name="notificationStartDate"
                            dateFormat="YYYY-MM-DD"
                            locale={datePickerLocaleCode}
                            minDate={moment()}
                            autoComplete={"off"}
                            control={control}
                            transformBeforeOnChange={(moment: Moment) => {
                                changeEndDateIfBeforeStartDate(moment);
                                return moment.toDate();
                            }}
                            isMandatory
                        />
                        <RHDatePickerInput
                            isFullWidth
                            formLabel={translate("notificationEndDate")}
                            name="notificationEndDate"
                            dateFormat="YYYY-MM-DD"
                            locale={datePickerLocaleCode}
                            minDate={minimumEndDate}
                            autoComplete={"off"}
                            control={control}
                            transformBeforeOnChange={(moment: Moment) => moment.toDate()}
                            isMandatory
                        />
                    </Grid>
                    <RHSelectInput
                        name="companyIds"
                        fullWidth
                        formLabel={translate("chooseCompany")}
                        control={control}
                        isClearable
                        isMulti
                        isDisabled={_targetAllCompanies}
                        options={companies}
                        menuPosition={"fixed"}
                        menuPortalTarget={document.body}
                    />
                    <RHCheckBoxInput
                        formLabel={translate("allCompanies")}
                        name="targetAllCompanies"
                        id="targetAllCompanies"
                        control={control}
                    />

                    {/** disabled in collection map in submit */}
                    <RHCheckBoxInput
                        formLabel={translate("activeNotifications")}
                        name="isActive"
                        id="isActive"
                        control={control}
                    />
                </ModalBody>
                <ModalActions>
                    <Button type="submit" themeColor="green" disabled={!isValid} isLoading={isSubmitting} fullWidth>
                        {translate("save")}
                    </Button>
                </ModalActions>
            </form>
        </Modal>
    );
};
