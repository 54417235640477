import React from "react";

import { Button, Flex, Header, Indicator, Stack, Text } from "Atoms";
import { useModal } from "Providers/modalProvider";
import { modalNames } from "Constants";
import { useMothershipMutation, useMothershipQuery } from "Hooks";
import { GET_ALL_QOPLA_PROMOTIONS } from "GraphQLQueries";
import { QoplaPromotionType } from "Types";
import { BOCard, BOCardBody, BOCardFooter } from "../../../../admin/shared";
import { useLanguage } from "LanguageProvider";
import { DiscountDate as NotificationDates } from "../../discount-admin/components/DiscountDate";
import { DELETE_QOPLA_PROMOTION } from "GraphQLMutations";
import { confirmNotification } from "TempUtils";
import { showToastError, showToastSuccess } from "Utils";
import { useQopla } from "Providers";
import { useQoplaPromotionToast } from "../../../../admin/shared/hooks";
import { useAccordionItemContext } from "Organisms";

export const QoplaPromotion: React.FC = () => {
    const { translate } = useLanguage();
    const { openModal } = useModal();

    const { authenticatedUser } = useQopla();
    const { isExpanded } = useAccordionItemContext();

    const { onHandlePromotionToast } = useQoplaPromotionToast();

    const { data, loading, refetch: refetchQoplaPromotions } =
        useMothershipQuery(GET_ALL_QOPLA_PROMOTIONS, { skip: !isExpanded });
    
    const [deleteQoplaPromotion] = useMothershipMutation(DELETE_QOPLA_PROMOTION);

    const openQoplaPromotionModal = (qoplaPromotion?: QoplaPromotionType) => {
        openModal(modalNames.QOPLA_PROMOTION_NOTIFICATION_MODAL, {
            qoplaPromotion: qoplaPromotion,
            refetchQoplaPromotions
        });
    };


    const qoplaPromotions: QoplaPromotionType[] = data?.getAllQoplaPromotions ?? [];

    const onHandleDeleteQoplaPromotion = async (promotion: QoplaPromotionType) => {
        const { value } = await confirmNotification(
            `${translate("delete")} ${promotion.name}?`,
            translate("areYouSure"),
            translate("yes")
        );
        if (!!value) {
            const response = await deleteQoplaPromotion({ variables: { qoplaPromotionId: promotion.id } });
            if (response.data?.deleteQoplaPromotion) {
                showToastSuccess(`${translate("ended")} - ${promotion.name}`);
                await refetchQoplaPromotions();
            } else {
                showToastError(translate("somethingWentWrong"));
            }
        }
    };

    const onHandleShowPreview = (qoplaPromotion: QoplaPromotionType) => {
        onHandlePromotionToast({
            qoplaPromotion: qoplaPromotion,
            authenicatedUser: authenticatedUser,
            preview: {
                showPreview: true,
                allowSubmit: true
            }
        });
    };

    return (
        <Flex direction={"column"} width={"100%"}>
            <Flex mb={4} direction={"column"}>
                {qoplaPromotions.map((qoplaPromotion: QoplaPromotionType) => {
                    const indicatorColor = qoplaPromotion.disabled ? "inactive" : "positive";
                    return (
                        <BOCard key={qoplaPromotion.id} mb={4} width={"full"}>
                            <BOCardBody>
                                <Stack>
                                    <Header size="md">
                                        <Flex align="baseline" justify="space-between">
                                            {qoplaPromotion.name}

                                            <Indicator ml={2} status={indicatorColor} />
                                        </Flex>
                                    </Header>
                                    <Text mb={2}>{qoplaPromotion.promotionMessage}</Text>
                                    <NotificationDates
                                        startDate={qoplaPromotion.notificationStartDate.toString()}
                                        endDate={qoplaPromotion.notificationEndDate.toString()}
                                    />
                                </Stack>
                            </BOCardBody>
                            <BOCardFooter flexDirection="column" height="auto">
                                <Flex>
                                    <Button
                                        size={"sm"}
                                        marginRight={4}
                                        onClick={() => openQoplaPromotionModal(qoplaPromotion)}
                                    >
                                        {translate("modify")}
                                    </Button>

                                    <Button
                                        size={"sm"}
                                        themeColor="red"
                                        onClick={() => onHandleDeleteQoplaPromotion(qoplaPromotion)}
                                    >
                                        {translate("delete")}
                                    </Button>
                                    <Button
                                        size={"sm"}
                                        themeColor="blue"
                                        ml={6}
                                        onClick={() => onHandleShowPreview(qoplaPromotion)}
                                    >
                                        {translate("preview")}
                                    </Button>
                                </Flex>
                            </BOCardFooter>
                        </BOCard>
                    );
                })}
            </Flex>
            <Button themeColor="green" onClick={() => openQoplaPromotionModal()} fullWidth>
                {translate("createQoplaPromotion")}
            </Button>
        </Flex>
    );
};
