import React, { PropsWithChildren, useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import { Formik, FormikState } from "formik-next";

import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    DrawerBody,
    DrawerActions
} from "Organisms";
import { useQopla } from "Providers/qoplaProvider";
import { IDrawerComponentContext } from "Providers/drawerProvider";
import { Languagekey, useLanguage } from "LanguageProvider";
import { Order, Subscription, SubscriptionCancellationEligibility, SubscriptionStatus, SubscriptionType } from "Types";
import { Box, Divider, Flex, Header, NewButton, Stack } from "Atoms";
import { confirmNotificationWithDate, errorNotification, successNotification } from "TempUtils";
import {
    Cancelling,
    CANCELORABORT,
    CANCELPERIOD,
    CancelReason,
    ExtendedOrder,
    RefundOrCancel,
    SUBMIT_TYPE,
    subscriptionStatusToText,
    UserSubscriptionOverview
} from "../types";
import { RefundBox } from "../components/RefundBox";
import { FlexLabelAndValue } from "../components/FlexLabelAndValue";
import { FormInput } from "Molecules";
import {
    CANCEL_USER_SUBSCRIPTION,
    CANCEL_USER_SUBSCRIPTION_FROM_GROUP,
    CANCEL_USER_SUBSCRIPTION_NOW,
    CREATE_WEB_FREE_AMOUNT_REFUND,
    SET_BINDING_PERIOD_END_DATE
} from "GraphQLMutations";
import { useMothershipMutation } from "Hooks";
import { CancelBox } from "../components/CancelBox";
import { useExtendPromise } from "./useExtendPromise";
import { GroupInformation } from "../components/GroupInformation";
import { getSubscriptionStatusColour, getTranslationForReason } from "../utils/subscriptionFunctions";
import { RefundSummary } from "../components/RefundSummary";
import { formatSwedishFinancialNumbers } from "PriceUtils";
import { isSuperAdminOrQoplaSupport, calculateCancellationEligibilityDate } from "Utils";

type Props = {
    userSubscription: UserSubscriptionOverview;
    subscription?: Subscription;
    updateRowByProp: (userSub: UserSubscriptionOverview, prop: keyof UserSubscriptionOverview) => void;
};

type ResetFunction = (nextState?: Partial<FormikState<RefundOrCancel>> | undefined) => void;
type CancelledSub = {
    reason?: string;
    detailedReason?: string;
    cancelledTimestamp?: moment.Moment;
    type?: CANCELORABORT;
    when?: CANCELPERIOD;
};
const GrayFlexBox: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Flex
            direction="column"
            justifyContent="flex-start"
            width="100%"
            p={5}
            mt={6}
            mb={10}
            height="fit-content"
            backgroundColor="gray.100"
        >
            {children}
        </Flex>
    );
};

export const UserSubscriptionDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { userSubscription, subscription, updateRowByProp },
    onCloseDrawer
}) => {
    const { translate, translateWithArgument } = useLanguage();
    const { authenticatedUser, selectedCompany } = useQopla();
    const isSuperAdminOrSupport = isSuperAdminOrQoplaSupport(authenticatedUser.roles);

    const [selectedPayment, onSelectedPayment] = useState<ExtendedOrder | null>(null);
    const [selectedUserSub, setSelectedUserSub] = useState<UserSubscriptionOverview>(userSubscription);
    const [statusIsUpdating, setStatusIsUpdating] = useState<SUBMIT_TYPE>(SUBMIT_TYPE.NONE);

    const [createWebFreeAmountRefund] = useMothershipMutation(CREATE_WEB_FREE_AMOUNT_REFUND);
    const [cancelUserSubsciptionOnDate] = useMothershipMutation(CANCEL_USER_SUBSCRIPTION);
    const [cancelUserSubsciptionNow] = useMothershipMutation(CANCEL_USER_SUBSCRIPTION_NOW);
    const [setBindingPeriodEndDate] = useMothershipMutation(SET_BINDING_PERIOD_END_DATE);
    const [cancelGroupUserSubsctription] = useMothershipMutation(CANCEL_USER_SUBSCRIPTION_FROM_GROUP);

    const {
        onGetPromise: onCancelSubscription,
        onClick: onOpenCancel,
        showConfirmation
    } = useExtendPromise<Cancelling>();

    const { subscriptionCancelReason, status, subscriptionPayments, recurringPaymentOrders } = selectedUserSub;
    const isPendingCancellation = !!subscriptionCancelReason && status !== SubscriptionStatus.CANCELLED;
    const statusUsedForStyling: SubscriptionStatus = isPendingCancellation ? SubscriptionStatus.PENDING : status;

    const translationText = subscriptionStatusToText[statusUsedForStyling];
    let statusText = translate((translationText ?? "") as Languagekey);

    if (isPendingCancellation) {
        const nextPaymentMoment = moment
            .utc(userSubscription.latestRecurringPaymentTimestamp)
            .add(userSubscription.chosenPricePlan.paymentFrequencyInMonths, "months");

        statusText = `${translate("subscriptionStatusActiveUntil")} ${nextPaymentMoment.format("YYYY-MM-DD")}`;
    }

    const isGroupSubscription = userSubscription.subscriptionType === SubscriptionType.GROUP;

    let refundedSubscriptionPayments = 0;
    const totalSubscriptionPayments = subscriptionPayments?.reduce((total: number, subOrder: Order) => {
        if (subOrder.totalAmount < 0) {
            refundedSubscriptionPayments += subOrder.totalAmount;
        }
        return (total += subOrder.totalAmount > 0 ? subOrder.totalAmount : 0);
    }, 0);

    const isCancelledAndGroupSub = statusUsedForStyling === SubscriptionStatus.CANCELLED && isGroupSubscription;
    const cancellationEligibilityDate = calculateCancellationEligibilityDate(selectedUserSub.createdAt, selectedUserSub.subscriptionCancellationEligibility);

    const disableCancelButton = ![
        SubscriptionStatus.ACTIVE,
        SubscriptionStatus.AT_RISK,
        SubscriptionStatus.PENDING
    ].includes(statusUsedForStyling);
    const disableRefundButtonAndInput = selectedPayment === null;

    const onCancelSubscriptionNow = async (cancelReasons: CancelledSub) => {
        try {
            const cancelledBy = translate("cancelledBy");
            const timeStamp = userSubscription.subscriptionCancelReason?.cancelledTimestamp
                ? userSubscription.subscriptionCancelReason.cancelledTimestamp
                : new Date().getTime();
            const cancelledReason = {
                reason: `${cancelReasons.reason}`,
                detailedReason: `${!!cancelReasons.detailedReason
                    ? cancelReasons.detailedReason
                    : `${cancelledBy} - ${authenticatedUser.fullName}`
                    }`,
                cancelledTimestamp: timeStamp
            };
            const { data } = await cancelUserSubsciptionNow({
                variables: {
                    userSubscriptionId: userSubscription.id,
                    subscriptionCancelReason: cancelledReason
                }
            });
            if (!!data) {
                const updatedUserSub = {
                    ...userSubscription,
                    status: SubscriptionStatus.CANCELLED
                };
                setSelectedUserSub(updatedUserSub);
                updateRowByProp(updatedUserSub, "id");
            } else {
                errorNotification(translate("somethingWentWrong"));
            }
        } catch (error) {
            errorNotification(translate("somethingWentWrong"));
        }
    };

    const onCancelGroupSubscription = async (cancelReasons: CancelledSub) => {
        try {
            const cancelledBy = translate("cancelledBy");
            const timeStamp = userSubscription.subscriptionCancelReason?.cancelledTimestamp
                ? userSubscription.subscriptionCancelReason.cancelledTimestamp
                : new Date().getTime();
            const cancelledReason = {
                reason: `${cancelReasons.reason}`,
                detailedReason: `${!!cancelReasons.detailedReason
                    ? cancelReasons.detailedReason
                    : `${cancelledBy} - ${authenticatedUser.fullName}`
                    }`,
                cancelledTimestamp: timeStamp
            };
            const { data: cancelUserFromGroupSubscription = false } = await cancelGroupUserSubsctription({
                variables: {
                    subscriptionId: userSubscription.subscriptionId,
                    userSubscriptionId: userSubscription.id,
                    subscriptionCancelReason: cancelledReason
                }
            });

            if (cancelUserFromGroupSubscription) {
                const updatedUserSub = {
                    ...userSubscription,
                    status: SubscriptionStatus.CANCELLED
                };
                setSelectedUserSub(updatedUserSub);
                updateRowByProp(updatedUserSub, "id");
            } else {
                errorNotification(translate("somethingWentWrong"));
            }
        } catch (error) {
            errorNotification(translate("somethingWentWrong"));
        }
    };

    const onCancelSubscriptionByDate = async (cancelReasons: CancelledSub) => {
        try {
            const cancelledBy = translate("cancelledBy");
            const cancelledReason: CancelReason = {
                reason: `${cancelReasons.reason}`,
                detailedReason: `${!!cancelReasons.detailedReason
                    ? cancelReasons.detailedReason
                    : `${cancelledBy} - ${authenticatedUser.fullName}`
                    }`,
                cancelledTimestamp: new Date().getTime()
            };
            const {
                data: { cancelUserSubscription = false }
            } = await cancelUserSubsciptionOnDate({
                variables: {
                    userSubscriptionId: userSubscription.id,
                    subscriptionCancelReason: cancelledReason
                }
            });
            if (cancelUserSubscription) {
                const updatedUserSub: UserSubscriptionOverview = {
                    ...userSubscription,
                    subscriptionCancelReason: {
                        ...cancelledReason,
                        cancelledTimestamp: new Date()
                    }
                };
                setSelectedUserSub(updatedUserSub);
                updateRowByProp(updatedUserSub, "id");
            } else {
                errorNotification(translate("somethingWentWrong"));
            }
        } catch (error) {
            errorNotification(translate("somethingWentWrong"));
        }
    };

    const onCreateRefund = async (values: RefundOrCancel) => {
        try {
            const { data } = await createWebFreeAmountRefund({
                variables: {
                    amount: values.amount,
                    referenceOrderId: values.orderPaymentid,
                    vatRate: values.vatRate,
                    comment: null,
                    customerEmail: userSubscription.customerEmail
                }
            });
            if (data && data.createWebFreeAmountRefund) {
                const { webPaymentResponse } = data.createWebFreeAmountRefund;

                if (webPaymentResponse?.operationSuccess) {
                    const { order } = data.createWebFreeAmountRefund;
                    const updatedUserSub = {
                        ...userSubscription,
                        subscriptionPayments: [...(subscriptionPayments ?? []), order],
                        recurringPaymentOrders: [...recurringPaymentOrders, order.id]
                    };
                    setSelectedUserSub(updatedUserSub);
                    updateRowByProp(updatedUserSub, "id");
                    successNotification(
                        translateWithArgument("refundSentTo", userSubscription.customerEmail ?? ""),
                        1400
                    );
                } else {
                    errorNotification(translate("somethingWentWrong"));
                }
            } else {
                errorNotification(translate("somethingWentWrong"));
            }
        } catch (errors) {
            errorNotification(translate("somethingWentWrong"));
        }
    };

    const onHandleRefundOrCancel = async (values: RefundOrCancel, resetForm: ResetFunction) => {
        switch (values.submitType) {
            case SUBMIT_TYPE.CANCEL: {
                const data = await onCancelSubscription();
                if (data.type !== CANCELORABORT.ABORT) {
                    if (data.when === CANCELPERIOD.NOW) {
                        if (values.subscriptionType === SubscriptionType.SINGLE) {
                            await onCancelSubscriptionNow(data);
                        } else {
                            await onCancelGroupSubscription(data);
                        }
                    } else if (data.when === CANCELPERIOD.LATER) {
                        await onCancelSubscriptionByDate(data);
                    }
                }
                break;
            }
            case SUBMIT_TYPE.REFUND: {
                await onCreateRefund(values);
                break;
            }
            default: {
                errorNotification(translate("somethingWentWrong"));
                break;
            }
        }
        onSelectedPayment(null);
        resetForm();
    };

    const validationSchema = Yup.object().shape({
        amount: Yup.number().when("submitType", {
            is: type => type === SUBMIT_TYPE.REFUND || type === SUBMIT_TYPE.NONE,
            then: Yup.number()
                .max(
                    selectedPayment?.totalAmountLeft ?? 0,
                    `Max ${formatSwedishFinancialNumbers(selectedPayment?.totalAmountLeft ?? 0)}`
                )
                .min(1, `${translate("minimum")} 1kr`)
                .required(translate("requiredError"))
        })
    });

    // related to issue https://github.com/qopla/fronty/issues/4457
    // vatRate shouldn't be 0;
    // so if it's 0 in subscriptionMeta, then let's take it from company settings
    const _vatRate =
        selectedPayment?.subscriptionMeta.vatRate ||
        selectedCompany.settings?.companySubscriptionSettings?.vatRate ||
        0;
    
    const onAdjustBindingPeriodEndDate = async () => {
        const confirmationNotificationWithDateResult = await confirmNotificationWithDate(
            translate("bindingPeriodAdjustBindingPeriodEndDate"),
            translate("bindingPeriodAdjustBindingPeriodEndDateDetails"),
            `${translate("bindingPeriodAdjustBindingPeriodEndDateChooseNewDate")}: `,
            cancellationEligibilityDate
        );

        const { value } = confirmationNotificationWithDateResult;
        const newBindingPeriodEndDate = value ? new Date(value) : null;

        if (!value) {
            console.warn("No new binding period end date chosen");
        } else {
            try {
                const { data } = await setBindingPeriodEndDate({
                    variables: {
                        userSubscriptionId: userSubscription.id,
                        newBindingPeriodEndDate: newBindingPeriodEndDate
                    }
                });

                if (data?.setBindingPeriodEndDate) {
                    successNotification(translate("successful"), 1400);
                    const updatedUserSub = {
                        ...userSubscription,
                        subscriptionCancellationEligibility: {
                            ...userSubscription.subscriptionCancellationEligibility,
                            type: "FIXED",
                            fixedDate: newBindingPeriodEndDate
                        } as SubscriptionCancellationEligibility
                    };
                    setSelectedUserSub(updatedUserSub);
                    updateRowByProp(updatedUserSub, "id");
                } else {
                    console.error("Failed to set new binding period end date");
                    errorNotification(translate("FAILED"), 1400);
                }
            } catch (error) {
                console.error("Failed to set new binding period end date", error);
                errorNotification(translate("FAILED"), 1400);
            }
        }
    }

    const initialValues: RefundOrCancel = {
        userSubscriptionId: selectedUserSub.id,
        orderPaymentid: selectedPayment?.id ?? "",
        maxRefundAmount: selectedPayment?.totalAmountLeft ?? 0,
        amount: selectedPayment?.totalAmountLeft ?? 0,
        vatRate: _vatRate,
        submitType: SUBMIT_TYPE.NONE,
        subscriptionType: selectedUserSub.subscriptionType
    };

    const currentStatusIsCancel = statusIsUpdating === SUBMIT_TYPE.CANCEL;
    const currentStatusIsRefund = statusIsUpdating === SUBMIT_TYPE.REFUND;

    const subCancelReason = userSubscription?.subscriptionCancelReason;
    const reason = subCancelReason?.reason ? getTranslationForReason(subCancelReason) : "";
    const detailedReason = subCancelReason?.detailedReason ?? "";
    const cancelReason = reason && detailedReason ? reason + ", " + detailedReason : reason ?? detailedReason;
    const cancelledTimestamp = subCancelReason?.cancelledTimestamp;
    const isNextRetryInFuture = moment(userSubscription?.currentRetryStatus?.nextScheduledRetryDate).isAfter(moment());
    return (
        <Drawer open onClose={onCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader display="flex" justifyContent="center" alignItems="center">
                    {userSubscription.customerName} - {subscription?.name}
                </DrawerHeader>
                <DrawerCloseButton top="15px" />
                <DrawerBody overflow="auto" pt="0">
                    <Flex direction="column" justifyContent="flex-start" alignItems="center" height="100%">
                        {isGroupSubscription && (
                            <GrayFlexBox>
                                <GroupInformation subscription={subscription} userSubscription={selectedUserSub} />
                            </GrayFlexBox>
                        )}
                        <GrayFlexBox>
                            <FlexLabelAndValue mt={2} label={translate("name")} value={userSubscription.customerName} />
                            <FlexLabelAndValue
                                mt={2}
                                label={translate("email")}
                                value={userSubscription.customerEmail}
                            />

                            <Divider />
                            <FlexLabelAndValue
                                mt={2}
                                label={translate("subscriptionName")}
                                value={subscription?.name}
                            />
                            <FlexLabelAndValue
                                mt={2}
                                label={translate("subscriptionPricePlanName")}
                                value={userSubscription.chosenPricePlan?.name}
                            />
                            {isGroupSubscription && <Header size="sm">{translate("subscriptions")}:</Header>}
                            {isPendingCancellation && (
                                <Flex justifyContent="center" fontWeight="bold" alignItems="center" color="red.500">
                                    {translate(subscriptionStatusToText.CANCELLED as Languagekey)}
                                </Flex>
                            )}
                            <FlexLabelAndValue
                                mt={2}
                                label={translate("status")}
                                valueColour={getSubscriptionStatusColour(statusUsedForStyling)}
                                value={statusText}
                                loading={currentStatusIsCancel}
                            />
                            {isSuperAdminOrSupport && !!cancelReason && (
                                <FlexLabelAndValue label={translate("reason")} value={cancelReason} />
                            )}
                            <FlexLabelAndValue
                                label={translate("startDate")}
                                value={moment(userSubscription.createdAt).format("YYYY-MM-DD")}
                            />

                            {cancellationEligibilityDate && (
                                <Stack stretch={0} my={2}>
                                    <FlexLabelAndValue
                                        label={`${translate("boundUntil")}:`}
                                        value={moment(cancellationEligibilityDate).format("YYYY-MM-DD")}
                                    />
                                    <Flex
                                        mt={1}
                                        justifyContent="flex-end"
                                        width="100%"
                                        alignItems="center"
                                        flexDirection={["column", "column", "row"]}
                                    >
                                        <NewButton size="sm" variant="outline" themeColor="orange" onClick={onAdjustBindingPeriodEndDate}>
                                            {translate("bindingPeriodAdjustBindingPeriodEndDate")}
                                        </NewButton>
                                    </Flex>
                                </Stack>
                            )}
                            {isSuperAdminOrSupport && !!cancelledTimestamp && (
                                <FlexLabelAndValue
                                    label={translate("endDate")}
                                    colour="red.500"
                                    value={moment(cancelledTimestamp).format("YYYY-MM-DD")}
                                />
                            )}
                            <Divider />
                            <FlexLabelAndValue
                                label={translate("pricePlan")}
                                value={formatSwedishFinancialNumbers(userSubscription.chosenPricePlan.amount)}
                            />
                            <FlexLabelAndValue
                                label={translate("paymentInterval")}
                                value={userSubscription.chosenPricePlan.paymentFrequencyInMonths}
                                hide={isGroupSubscription}
                            />
                            <Divider />
                            <FlexLabelAndValue
                                label={translate("latestPaymentDate")}
                                value={moment(userSubscription.latestRecurringPaymentTimestamp).format("YYYY-MM-DD")}
                                hide={isGroupSubscription}
                            />
                            <FlexLabelAndValue
                                label={translate("subscriptionPayments")}
                                value={selectedUserSub.recurringPaymentOrders.length}
                                hide={isGroupSubscription}
                                loading={currentStatusIsRefund}
                            />
                            {/* Insert retry info here with divider */}
                            {userSubscription.currentRetryStatus && (
                                <>
                                    <Divider />
                                    <FlexLabelAndValue
                                        label={
                                            isNextRetryInFuture
                                                ? translate("nextScheduledRetry")
                                                : translate("latestScheduledRetry")
                                        }
                                        value={moment(
                                            userSubscription.currentRetryStatus.nextScheduledRetryDate
                                        ).format("YYYY-MM-DD")}
                                        hide={isGroupSubscription}
                                    />
                                    <FlexLabelAndValue
                                        label={translate("currentFailedAttempts")}
                                        value={selectedUserSub.currentRetryStatus.failedRetryOrderIds.length}
                                        hide={isGroupSubscription}
                                    />
                                </>
                            )}
                            <Divider />
                            <FlexLabelAndValue
                                label={translate("orders")}
                                value={userSubscription.subscriptionRedemptionOrders?.length ?? 0}
                            />
                            <FlexLabelAndValue
                                mt={8}
                                label={translate("sumOfAllSubscriptionPayments")}
                                value={formatSwedishFinancialNumbers(totalSubscriptionPayments ?? 0)}
                                hide={isGroupSubscription}
                            />
                            <FlexLabelAndValue
                                mt={8}
                                label={translate("sumOfAllSubscriptionRefunds")}
                                colour="red.500"
                                value={formatSwedishFinancialNumbers(refundedSubscriptionPayments)}
                                hide={isGroupSubscription}
                                loading={currentStatusIsRefund}
                            />
                        </GrayFlexBox>
                    </Flex>
                </DrawerBody>
                <DrawerActions position={"absolute"} bottom={0} width="100%">
                    {showConfirmation && (
                        <CancelBox m={2} onOpenCancel={onOpenCancel} userSubscription={selectedUserSub} />
                    )}
                    {!isCancelledAndGroupSub && (
                        <Formik<RefundOrCancel>
                            validationSchema={validationSchema}
                            enableReinitialize
                            initialValues={initialValues}
                            onSubmit={async (values, { resetForm }) => {
                                setStatusIsUpdating(values.submitType);
                                await onHandleRefundOrCancel(values, resetForm);
                                setStatusIsUpdating(SUBMIT_TYPE.NONE);
                            }}
                        >
                            {({ values, handleSubmit, setFieldValue, isSubmitting, isValid }) => {
                                const noRefundAmount = values.amount === 0;
                                const shouldHideRefundButton = isSubmitting || disableRefundButtonAndInput;
                                const disableCancel = isSubmitting || disableCancelButton;
                                const isCancelling = isSubmitting && values.submitType === SUBMIT_TYPE.CANCEL;
                                const isRefunding = isSubmitting && values.submitType === SUBMIT_TYPE.REFUND;

                                return (
                                    <>
                                        <form onSubmit={handleSubmit} style={{ height: "100%" }}>
                                            {!showConfirmation && (
                                                <RefundBox
                                                    m={2}
                                                    subscriptionPayments={subscriptionPayments ?? []}
                                                    onSelectedPayment={onSelectedPayment}
                                                    isUpdating={currentStatusIsRefund}
                                                />
                                            )}
                                            {!shouldHideRefundButton && (
                                                <FormInput
                                                    name="amount"
                                                    formLabel={`${translate("refundAmount")}: `}
                                                    fullWidth
                                                    type="number"
                                                    disabled={disableRefundButtonAndInput}
                                                />
                                            )}
                                            {!noRefundAmount && <RefundSummary values={values} />}
                                            <Flex justifyContent="space-between" width="100%" height="auto">
                                                <Box>
                                                    {!shouldHideRefundButton && (
                                                        <NewButton
                                                            type="submit"
                                                            themeColor="blue"
                                                            variant="outline"
                                                            isLoading={isRefunding}
                                                            size="lg"
                                                            disabled={!isValid}
                                                            onClick={() =>
                                                                setFieldValue("submitType", SUBMIT_TYPE.REFUND)
                                                            }
                                                        >
                                                            {translate("refund")}
                                                        </NewButton>
                                                    )}
                                                </Box>
                                                <NewButton
                                                    type="submit"
                                                    themeColor="red"
                                                    variant="outline"
                                                    size="lg"
                                                    isLoading={isCancelling}
                                                    disabled={disableCancel}
                                                    onClick={() => setFieldValue("submitType", SUBMIT_TYPE.CANCEL)}
                                                >
                                                    {translate("cancelSubscription")}
                                                </NewButton>
                                            </Flex>
                                        </form>
                                    </>
                                );
                            }}
                        </Formik>
                    )}
                </DrawerActions>
            </DrawerContent>
        </Drawer>
    );
};
