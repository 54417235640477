import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Flex, RHSelectInput, LoadingMessage, Button } from "Atoms";
import { useMothershipQuery, useMothershipMutation, useMothershipLazyQuery } from "Hooks";
import { GET_SUPER_ADMIN_COMPANIES, GET_PUBLIC_SHOPS_INFO_BY_COMPANY_ID } from "GraphQLQueries";
import { COPY_USER_PROFILES_TO_COMPANY } from "GraphQLMutations";
import { useAccordionItemContext } from "Organisms";
import { useLanguage } from "LanguageProvider";
import { useQopla } from "Providers";
import { Shop } from "Types";
import { confirmNotification } from "TempUtils";
import { isGodMode } from "Utils";
import { toast } from "react-toastify";

type FormValues = {
    fromCompanyId: string;
    fromShopId: string;
    toCompanyId: string;
    toShopId: string;
};

export const CopyUserProfiles = () => {
    const [fromShops, setFromShops] = useState([]);
    const [toShops, setToShops] = useState([]);

    const { authenticatedUser } = useQopla();
    const { isExpanded } = useAccordionItemContext();
    const { translate, translateWithMultipleArguments } = useLanguage();

    const isAllowed = isGodMode(authenticatedUser.roles);

    const { data: getCompanies, loading } = useMothershipQuery(GET_SUPER_ADMIN_COMPANIES, {
        fetchPolicy: "network-only",
        skip: !isExpanded
    });

    const [getFromShops, { data: fromShopsData }] = useMothershipLazyQuery(GET_PUBLIC_SHOPS_INFO_BY_COMPANY_ID, {
        fetchPolicy: "no-cache"
    });

    const [getToShops, { data: toShopsData }] = useMothershipLazyQuery(GET_PUBLIC_SHOPS_INFO_BY_COMPANY_ID, {
        fetchPolicy: "no-cache"
    });

    const [copyUserProfilesToCompany] = useMothershipMutation(COPY_USER_PROFILES_TO_COMPANY);

    const validationSchema = yup.object().shape({
        fromCompanyId: yup.string().required(translate("requiredError")),
        fromShopId: yup.string().required(translate("requiredError")),
        toCompanyId: yup.string().required(translate("requiredError")),
        toShopId: yup.string().required(translate("requiredError"))
    });

    const { control, handleSubmit, setValue } = useForm<FormValues>({
        defaultValues: { fromCompanyId: "", fromShopId: "", toCompanyId: "", toShopId: "" },
        resolver: yupResolver(validationSchema)
    });

    const fromCompanyId = useWatch({ control, name: "fromCompanyId" });
    const toCompanyId = useWatch({ control, name: "toCompanyId" });

    useEffect(() => {
        if (fromCompanyId) {
            getFromShops({ variables: { companyId: fromCompanyId } });
        }
    }, [fromCompanyId]);

    useEffect(() => {
        if (toCompanyId) {
            getToShops({ variables: { companyId: toCompanyId } });
        }
    }, [toCompanyId]);

    useEffect(() => {
        if (fromShopsData?.getShopsPublicInfoByCompanyId?.length) {
            setFromShops(fromShopsData.getShopsPublicInfoByCompanyId);
            setValue("fromShopId", fromShopsData?.getShopsPublicInfoByCompanyId[0]?.id);
        }
    }, [fromShopsData]);

    useEffect(() => {
        if (toShopsData?.getShopsPublicInfoByCompanyId?.length) {
            setToShops(toShopsData.getShopsPublicInfoByCompanyId);
            setValue("toShopId", toShopsData?.getShopsPublicInfoByCompanyId[0]?.id);
        }
    }, [toShopsData]);

    if (loading || !getCompanies) {
        return <LoadingMessage />;
    }

    const companyList = getCompanies.getSuperAdminCompanyList
        .map((companySettings: any) => ({
            label: companySettings.company.name,
            value: companySettings.company.id
        }))
        .sort((a: any, b: any) => a.label.localeCompare(b.label));

    const fromShopList: any[] = !!fromShops?.length
        ? fromShops.map((shop: Shop) => ({ value: shop.id, label: shop.name }))
        : [];
    const toShopList: any[] = !!toShops?.length
        ? toShops.map((shop: Shop) => ({ value: shop.id, label: shop.name }))
        : [];

    const onSubmit = async (formValues: FormValues) => {
        const fromCompanyName = companyList.find((company: any) => company.value === formValues.fromCompanyId)?.label;
        const fromShopName = fromShopList.find((shop: any) => shop.value === formValues.fromShopId)?.label;

        const toCompanyName = companyList.find((company: any) => company.value === formValues.toCompanyId)?.label;
        const toShopName = toShopList.find((shop: any) => shop.value === formValues.toShopId)?.label;

        const confirm = await confirmNotification(
            translate("areYouSure"),
            translateWithMultipleArguments("copyUserProfilesConfirmation", [
                fromCompanyName,
                fromShopName,
                toCompanyName,
                toShopName
            ]),
            translate("yes")
        );
        if (confirm.value) {
            const resp = await copyUserProfilesToCompany({
                variables: {
                    fromCompanyId: formValues.fromCompanyId,
                    fromShopId: formValues.fromShopId,
                    toCompanyId: formValues.toCompanyId,
                    toShopId: formValues.toShopId
                }
            });
            if (resp) {
                const amountOfProfiles = resp.data?.copyUserOrderProfilesFromCompanyAndShopToCompanyAndShop ?? 0;
                if (!!amountOfProfiles) {
                    toast.success(translateWithMultipleArguments("copiedUserProfiles", [amountOfProfiles]));
                } else {
                    toast.warning(translate("allPresent"));
                }
            } else {
                toast.error(translate("somethingWentWrong"));
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex pb={16}>
                <Flex direction={"column"} flex={1} borderRight={"1px solid"} borderColor={"gray.500"} pr={8}>
                    <Box fontSize="lg" fontWeight={"600"}>
                        {translate("copyFrom")}
                    </Box>
                    <RHSelectInput
                        name="fromCompanyId"
                        control={control}
                        formLabel={translate("company")}
                        options={companyList}
                        wrapperProps={{ width: "100%" }}
                        isFullWidth
                        isClearable
                    />
                    <RHSelectInput
                        name="fromShopId"
                        control={control}
                        formLabel={translate("restaurant")}
                        options={fromCompanyId ? fromShopList : []}
                        wrapperProps={{ width: "100%" }}
                        isFullWidth
                        isClearable
                    />
                </Flex>
                <Flex direction={"column"} pl={8} flex={1}>
                    <Box fontSize="lg" fontWeight={"600"}>
                        {translate("copyTo")}
                    </Box>
                    <RHSelectInput
                        name="toCompanyId"
                        control={control}
                        formLabel={translate("company")}
                        options={companyList}
                        wrapperProps={{ width: "100%" }}
                        isFullWidth
                    />
                    <RHSelectInput
                        name="toShopId"
                        control={control}
                        formLabel={translate("restaurant")}
                        options={toCompanyId ? toShopList : []}
                        wrapperProps={{ width: "100%" }}
                        isFullWidth
                        isClearable
                    />
                </Flex>
            </Flex>
            <Button type="submit" themeColor="green" fullWidth disabled={!isAllowed}>
                {translate("copyUserProfiles")}
            </Button>
        </form>
    );
};
