import React, { useEffect } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment, { Moment } from "moment";
import { v4 as uuidv4 } from "uuid";

import {
    RHCheckBoxInput,
    RHFormInput,
    RHNumericInput,
    RHSelectInput,
    NewGrid as Grid,
    Button,
    RHDatePickerInput
} from "Atoms";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader, SelectOption } from "Molecules";
import { modalNames, PosTypes } from "Constants";
import { Languagekey, useLanguage } from "LanguageProvider";
import { CountryLocale, CountryLocaleId, CurrencySymbol, Discount, EatingOption } from "Types";
import { getMinimumEndDateFromStartDate, parseDateForJavaLocalDateTime, showToastSuccess } from "Utils";
import { useMothershipMutation } from "Hooks";
import { UPSERT_DISCOUNT } from "GraphQLMutations";

type Props = {
    modalContent: { discount: Discount; refetch: () => Promise<void> };
    closeModal: (modal: string) => void;
};

type GlobalDiscountKeys =
    | "name"
    | "amount"
    | "availableOn"
    | "eatingOptions"
    | "maxRedeemed"
    | "noRedeemed"
    | "qoplaSponsored"
    | "disabled"
    | "minimumSpend"
    | "id";

type GlobalDiscount = { startDate: Date; endDate?: Date | null | undefined } & Pick<Discount, GlobalDiscountKeys>;

export const GlobalDiscountModal: React.FC<Props> = ({ modalContent, closeModal }) => {
    const randomIdForCode = uuidv4();
    const { translate } = useLanguage();
    const [createDiscount] = useMothershipMutation(UPSERT_DISCOUNT);

    const { discount, refetch } = modalContent;

    const countryLocale: CountryLocale = {
        localeCode: CountryLocaleId.sv_SE,
        currencyCode: CurrencySymbol.sv_SE
    };

    const globalDiscountSchema = Yup.object().shape({
        name: Yup.string().required(translate("mustFillIn")),
        amount: Yup.number()
            .required(translate("mustFillIn"))
            .test("amount_higher_than_zero", translate("fillThisOut"), function (value: number) {
                return value > 0;
            }),
        minimumSpend: Yup.number()
            .required(translate("mustFillIn"))
            .test("minimumSpend_zero_or_higher", translate("fillThisOut"), function (value: number) {
                return value >= 0;
            }),
        availableOn: Yup.array().required(translate("mustSelectAtLeastOne")),
        eatingOptions: Yup.array().required(translate("mustSelectAtLeastOne")),
        startDate: Yup.date()
            .required(translate("mustChooseAStartDate"))
            .test("validateStartDate", translate("mustChooseAStartDate"), (value: moment.Moment) => {
                return moment(value).isValid();
            }),
        endDate: Yup.date()
            .nullable()
            .test("validateEndDate", translate("mustChooseAnEndDate"), (value: moment.Moment) => {
                if (!value) {
                    return true;
                }
                return moment(value).isValid();
            })
            .test("endDate_after_startDate", translate("mustChooseAnEndDate"), function (endDate) {
                const startDate = this.parent.startDate;
                if (!endDate) return true;
                return moment(endDate).isAfter(moment(startDate));
            })
    });

    const editDiscount = !!discount;

    const defaultValues = {
        name: discount?.name || "",
        startDate: discount?.startDate || new Date(),
        availableOn: discount?.availableOn || [PosTypes.ONLINE],
        eatingOptions: discount?.eatingOptions || Reflect.ownKeys(EatingOption),
        maxRedeemed: discount?.maxRedeemed || 0,
        noRedeemed: discount?.noRedeemed || 0,
        qoplaSponsored: discount?.qoplaSponsored || true,
        minimumSpend: discount?.minimumSpend || 0,
        ...(editDiscount && {
            id: discount.id,
            disabled: !discount.disabled,
            amount: discount.amount,
            endDate: discount.endDate
        })
    };

    const {
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = useForm<GlobalDiscount>({
        mode: "onBlur",
        defaultValues: defaultValues as GlobalDiscount,
        resolver: yupResolver(globalDiscountSchema)
    });

    const _startDate = useWatch({ control, name: "startDate" });
    const _endDate = useWatch({ control, name: "endDate" });
    const _minSpend = useWatch({ control, name: "minimumSpend" });

    const datePickerLocaleCode = countryLocale.localeCode.toLowerCase();
    const minimumEndDate = getMinimumEndDateFromStartDate(_startDate?.toString());

    const changeEndDateIfBeforeStartDate = (startDate: Moment) => {
        if (_endDate && moment(_endDate).isBefore(startDate)) {
            setValue("endDate", startDate.toDate());
        }
    };

    const availableOnOptions = [PosTypes.ONLINE, PosTypes.POS, PosTypes.EXPRESS].map(type => {
        if (type === PosTypes.EXPRESS) {
            return { label: `${translate("expressPOS")} V2`, value: type, isDisabled: true };
        }
        return { label: translate(type as Languagekey), value: type, isDisabled: type !== PosTypes.ONLINE };
    });

    const pickupOptions: SelectOption[] = [
        { value: EatingOption.EAT_HERE, label: translate(EatingOption.EAT_HERE) },
        { value: EatingOption.TAKE_AWAY, label: translate(EatingOption.TAKE_AWAY) },
        { value: EatingOption.HOME_DELIVERY, label: translate(EatingOption.HOME_DELIVERY) },
        { value: EatingOption.CATERING, label: translate(EatingOption.CATERING) }
    ];

    const onSubmit: SubmitHandler<GlobalDiscount> = async (values: GlobalDiscount) => {
        const fullDiscount = {
            ...values,
            rate: null,
            disabled: !values.disabled,
            emailOnlyDiscount: false,
            canApplyOnWholeOrder: true,
            canApplyOnEachProduct: false,
            shopIds: [],
            code: !!discount?.code ? discount.code : randomIdForCode,
            startDate: !!values.startDate ? parseDateForJavaLocalDateTime(moment(values.startDate).startOf("day")) : "",
            endDate: !!values?.endDate ? parseDateForJavaLocalDateTime(moment(values.endDate).endOf("day")) : null
        };

        try {
            const { data } = await createDiscount({ variables: { discount: fullDiscount } });
            if (data) {
                closeModal(modalNames.GLOBAL_DISCOUNT);
                refetch();
                const updatedOrCreated = editDiscount ? translate("updated") : translate("created");
                const discountCreated = `${translate("discount")} ${values.name} - ${updatedOrCreated}`;
                showToastSuccess(discountCreated);
            }
        } catch (error) {
            console.log("Posting discount failed!!", error);
        }
    };

    useEffect(() => {
        if (!_minSpend) {
            setValue("minimumSpend", 0, { shouldDirty: true, shouldValidate: true });
        }
    }, [_minSpend]);

    return (
        <Modal open={true} onClose={() => closeModal(modalNames.GLOBAL_DISCOUNT)} size="xl" isScrolling>
            <ModalCloseBtn onClick={() => closeModal(modalNames.GLOBAL_DISCOUNT)} />
            <ModalHeader>{translate("qoplaDiscount")}</ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody overflow="auto">
                    <RHFormInput
                        name="name"
                        fullWidth
                        formLabel={translate("name")}
                        control={control}
                        placeholder={translate("name")}
                        isMandatory
                    />
                    <RHNumericInput
                        control={control}
                        name="amount"
                        fullWidth
                        min={0}
                        placeholder={translate("amountAlt")}
                        formLabel={translate("amountAlt")}
                        isMandatory
                        isFullWidth
                    />
                    <RHSelectInput
                        formLabel={translate("availableOn")}
                        placeholder={translate("availableOn")}
                        noOptionsMessage={() => translate("noAlternative")}
                        name="availableOn"
                        options={availableOnOptions}
                        isMulti
                        control={control}
                        isMandatory
                    />
                    <RHSelectInput
                        formLabel={translate("pickUpSelection")}
                        placeholder={translate("pickUpSelection")}
                        noOptionsMessage={() => translate("noAlternative")}
                        name="eatingOptions"
                        options={pickupOptions}
                        isMulti
                        control={control}
                    />
                    <Grid templateColumns="1fr 1fr" gap="1em">
                        <RHDatePickerInput
                            isFullWidth
                            formLabel={translate("startDate")}
                            name="startDate"
                            dateFormat="YYYY-MM-DD"
                            locale={datePickerLocaleCode}
                            minDate={moment()}
                            autoComplete={"off"}
                            control={control}
                            transformBeforeOnChange={(moment: Moment) => {
                                changeEndDateIfBeforeStartDate(moment);
                                return moment.toDate();
                            }}
                            isMandatory
                        />
                        <RHDatePickerInput
                            isFullWidth
                            formLabel={translate("endDate")}
                            name="endDate"
                            dateFormat="YYYY-MM-DD"
                            locale={datePickerLocaleCode}
                            minDate={minimumEndDate}
                            autoComplete={"off"}
                            control={control}
                            transformBeforeOnChange={(moment: Moment) => moment.toDate()}
                        />
                    </Grid>
                    <Grid templateColumns="1fr 1fr" gap="1em">
                        <RHNumericInput
                            name="maxRedeemed"
                            fullWidth
                            autoFocus
                            type="number"
                            placeholder={translate("maxUsage")}
                            formLabel={`${translate("maxUsage")} ${translate("zeroIsUnlimited")} - ${translate(
                                "online"
                            )} `}
                            control={control}
                        />
                        <RHNumericInput
                            name="noRedeemed"
                            fullWidth
                            type="number"
                            formLabel={`${translate("discountHasBeenUsed")} - ${translate("online")}`}
                            isDisabled={true}
                            control={control}
                        />
                    </Grid>

                    <RHFormInput
                        name="minimumSpend"
                        formLabel={`${translate("minimumSpend")}`}
                        control={control}
                        min={0}
                        type="number"
                        isFullWidth
                    />
                    <RHCheckBoxInput
                        formLabel={translate("activateDiscount")}
                        name="disabled"
                        id="disabled"
                        control={control}
                    />
                </ModalBody>
                <ModalActions>
                    <Button type="submit" themeColor="green" disabled={!isValid} isLoading={isSubmitting} fullWidth>
                        {translate("save")}
                    </Button>
                </ModalActions>
            </form>
        </Modal>
    );
};
